import { Component, OnInit } from '@angular/core';
import { WindowService } from '../../window-service.service';

export type SocialMediaService = 'facebook' | 'instagram';

export interface SocialMediaServiceUrl {
  url: string;
}

export interface SocialIcons {
  label: SocialMediaService;
  class: string;
}

export interface SocialLinks {
  [id: string]: SocialMediaServiceUrl;
}

@Component({
  selector: 'app-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.scss']
})
export class SocialLinksComponent implements OnInit {

  socialIcons: SocialIcons[] = [
    {
      label: 'instagram',
      class: 'icon-instagram',
    },
    {
      label: 'facebook',
      class: 'icon-facebook',
    },
  ];

  socialLinks: SocialLinks = {
    instagram: {
      url: 'https://www.instagram.com/kenneth_geoffreys/',
    },
    facebook: {
      url: 'https://www.facebook.com/kennethgeoffreys/',
    },
  };

  constructor(private readonly window: WindowService) { }

  ngOnInit(): void {
  }

  navigateToSocialPage(service: SocialMediaService): Window {
    const url = this.socialLinks[service].url;
    return this.window.nativeWindow.open(url, '_blank');
  }

}
