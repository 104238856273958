import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-judy-eric',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  scrollDistance: number;

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    this.scrollDistance = verticalOffset;
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
}
