<div class="about-us-container">
    <div class="about-us-text">
        <span>
            Kenneth Geoffrey’s was established in 2020. 
            Designed by Kizzi & built by her husband Michael during lockdown 2.0. 
            It was named in honour of Kizzi’s Grandfather Kenneth. 
            Our shop is designed to be a place where you can relax and unwind and have a good time.<br><br>
        </span>
        <img class="shop-photo" src="/assets/img/shop_workstations.jpg" alt="Green subway tiles with oakwood and brass fixtures">
        <span>
            Fitted with green tiles and brass fittings throughout the space, it creates a classic ambiance that stands out from the rest.
            Kenneth Geoffrey’s Barbershop is a place where you can come in for a top-quality haircut, but also a place where you can feel right at home. 
            Our team of expert barbers are some of the best in the business, with years’ experience under their belts. 
            They’re always up to date on the latest trends and will be sure to give you a cut that will turn heads. <br><br>
        </span>
        <img class="shop-photo" src="/assets/img/shop_wall.jpg" alt="Finish imported hair and grooming products with gold packaging">
        <span>
            And of course, were all about quality. We use only the best products and tools to ensure that you receive the best possible results. 
            Whether you’re looking for a classic cut or something a bit more trendy, we’ve got you covered.<br><br>
        </span>
        <img class="shop-photo" src="/assets/img/kizzi_shop.jpg" alt="Your royal barbershop matriarch, Kizzi">
        <span>
            We guarantee you’ll leave feeling refreshed, rejuvenated, and looking your best.<br><br>
        </span>
    </div>
    <div class="short-story">
        <span>
            When creating this space we imagined an old English train station set in the 1940's. 
            A dapper man sitting on the platform in a pin stripe suit, slicked back hair and a Cuban Cigar gripped in his fingers. 
            A whistle sounds from above, the train pulls in. He reaches for his briefcase, filled with his tools that have been carefully crafted to ensure the cleanest of shaves. 
            He twirls his moustache as he prepares for another day in the life of a barber.
        </span>
    </div>
</div>