import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnChanges {
  @Input()
  scrollDistance: number;

  private isSticky = false;

  constructor() { }

  ngOnChanges(): void {
    this.isSticky = this.scrollDistance > 200;
  }

  shouldComponentBeSticky(): boolean {
    return this.isSticky;
  }
}
