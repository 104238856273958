import { Component, OnInit } from '@angular/core';
import { WindowService } from 'src/app/window-service.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(private readonly window: WindowService) {}

  ngOnInit(): void {}

  navigateToSquareSite(): void {
    this.window.nativeWindow.location.href =
      'https://squareup.com/appointments/book/461gmb05vvrspr/LBE6DDM6VMF3Y/services';
  }
}
