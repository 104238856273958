import { Component, OnInit } from '@angular/core';
import { WindowService } from '../../window-service.service';

@Component({
  selector: 'app-book-now',
  templateUrl: './book-now.component.html',
  styleUrls: ['./book-now.component.scss']
})
export class BookNowComponent implements OnInit {
  constructor(private readonly window: WindowService) {}

  ngOnInit(): void {}

  navigateToSquareUp(): void {
    this.window.nativeWindow.location.href =
      'https://squareup.com/appointments/book/461gmb05vvrspr/LBE6DDM6VMF3Y/services';
  }

  shopOnline() {
    this.window.nativeWindow.location.href =
      'https://shop.kennethgeoffreys.com';
  }
}
