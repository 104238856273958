<div class="social-links-container">
    <div class="title">
        <h2>Follow us on social media for updates</h2>
    </div>
    <div class="social-icons">
        <div *ngFor="let icon of socialIcons" class="icon" (click)="navigateToSocialPage(icon.label)">
            <div class="icon-img" [class]="icon.class"></div>
            <div class="icon-label">
                <span>{{ icon.label }}</span>
            </div>
        </div>
    </div>
</div>
